<template>
  <FormComponent class="register" @submit="login">
    <h3 class="auth-title">
      <button class="btn" type="button" @click="back()">
        <span class="material-icons-outlined">arrow_back</span>
      </button>
      {{ $t('app.login') }}
    </h3>
    <InputComponent name="email" type="email" :label="$tc('app.email', 1)" :placeholder="$t('app.email')" required
      v-model="form.email"></InputComponent>
    <PasswordInputComponent :label="$tc('app.password', 1)" :placeholder="$t('login-invite.fullname')" required
      v-model="form.password" :validate="false"></PasswordInputComponent>
    <div class="checkbox">
      <router-link to="/reset-password" class="yup-purple-color">{{
        $t('login-invite.forgot-password')
      }}</router-link>
    </div>
    <ButtonComponent type="submit" is-block :loading="isSending">
      {{ $t('app.login') }}
    </ButtonComponent>
    <div class="mb-4" v-if="isMainWhitelabel">
      <SeparatorComponent text="Ou continue com" />
      <div class="d-flex align-items-center justify-content-center social-group">
        <GoogleButtonComponent @login="handleGoogleLogin"></GoogleButtonComponent>
        <FacebookButtonComponent @login="handleFacebookLogin"></FacebookButtonComponent>
      </div>
    </div>
  </FormComponent>
</template>

<script>
// @ is an alias to /src
import { load } from 'recaptcha-v3';
import User from '@/models/user';
import DarkMode from '@/assets/mixins/detectDark';
import FormComponent from '../../components/form/FormComponent.vue';
import InputComponent from '../../components/form/InputComponent.vue';
import PasswordInputComponent from '../../components/form/PasswordInputComponent.vue';
import ButtonComponent from '../../components/ui/ButtonComponent.vue';
import SeparatorComponent from '../../components/ui/SeparatorComponent.vue';
import GoogleButtonComponent from '../../components/ui/GoogleButtonComponent.vue';
import FacebookButtonComponent from '../../components/ui/FacebookButtonComponent.vue';

export default {
  name: 'Login',
  components: {
    FormComponent,
    InputComponent,
    PasswordInputComponent,
    ButtonComponent,
    SeparatorComponent,
    GoogleButtonComponent,
    FacebookButtonComponent,
  },
  mixins: [DarkMode],
  data() {
    return {
      form: {
        email: '',
        password: '',
        recaptcha_token: '',
        device: '',
      },
      user: new User('', ''),
      isSending: false,
      isSendingGoogle: false,
      isSendingFacebook: false,
      show: false,
    };
  },
  computed: {
    isMainWhitelabel() {
      return (
        this.$store.state?.admin?.whitelabel?.['is_main'] ||
        !this.$store.state?.whitelabel
      );
    },
  },
  mounted() {
    this.form.device = localStorage.getItem('mobile');
  },
  methods: {
    back() {
      if (this.$router.currentRoute.path === '/login') {
        this.$parent.routing = false;
      } else {
        this.$router.back();
      }
    },
    recaptcha(callback) {
      if (process.env.NODE_ENV === 'production') {
        load(process.env.VUE_APP_RECAPTCHA_KEY, {
          useRecaptchaNet: true,
          autoHideBadge: true,
        }).then((recaptcha) => {
          recaptcha.execute('login').then((token) => {
            this.form.recaptcha_token = token;
            callback();
          });
        });
      } else {
        this.form.account_name = 'localhost';
        callback();
      }
    },
    login() {
      if (this.isSending) return;

      this.isSending = true;

      this.recaptcha(() => {
        this.$store.dispatch('auth/login', this.form).then(
          () => {
            this.isSending = false;
            this.$live.firstLaunch = true;
            // this.$router.push('/2fa');
            this.$router.push('/');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      });
    },
    handleGoogleLogin(response) {
      this.$store.dispatch('auth/loginWithGoogle', response).then(() => {
        this.$live.firstLaunch = true;
        this.$router.push('/');
      });
    },
    handleFacebookLogin(response) {
      this.$store.dispatch('auth/loginWithFacebook', response).then(() => {
        this.$live.firstLaunch = true;
        this.$router.push('/');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.social-group {
  gap: 16px;
}

.auth-title {
  font-size: 36px;
  margin-bottom: 26px;

  .btn {
    padding: 0;
    font-size: 24px;
  }
}

.checkbox {
  margin-bottom: 26px;
  font-weight: 500;
}
</style>
