const darkMode = {
  methods: {
    setDark() {
      // get their darkMode setting
      const darkMode = localStorage.getItem('darkMode');

      // if it not current enabled, enable it
      if (darkMode !== 'enabled') {
        this.enableDarkMode();
      }
    },
    enableDarkMode() {
      localStorage.setItem('sys_theme', false);
      // 1. Add the class to the body
      document.body.classList.add('darkmode');
      // 2. Update darkMode in localStorage
      localStorage.setItem('darkMode', 'enabled');
    },
    disableDarkMode() {
      localStorage.setItem('sys_theme', false);
      // 1. Remove the class from the body
      document.body.classList.remove('darkmode');
      // 2. Update darkMode in localStorage
      localStorage.setItem('darkMode', false);
    },
    detectDark() {
      // const darkMode = localStorage.getItem('darkMode');
      // if (
      //   window.matchMedia &&
      //           window.matchMedia('(prefers-color-scheme: dark)').matches
      // ) {
      //   // dark mode
      //   if (darkMode !== 'enabled') {
      //     this.enableDarkMode();
      //     localStorage.setItem('sys_theme', true);
      //   }
      // } else {
      //   this.disableDarkMode();
      //   localStorage.setItem('sys_theme', true);
      // }
      this.disableDarkMode();
      localStorage.setItem('sys_theme', true);
    },
  },
};

export default darkMode;
