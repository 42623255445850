<template>
  <div class="separator d-flex">
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'SeparatorComponent',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.separator {
  margin: 28px 0;
  font-size: 0.82rem;

  &::before,
  &::after {
    position: relative;
    top: 10px;
    border-top: 2px solid #e5e5e5;
    flex-grow: 1 !important;
    content: '';
  }
}
</style>
