<template>
  <ButtonComponent class="facebook-btn" @click="handleClick">
    <i class="fab fa-facebook-f"></i>
  </ButtonComponent>
</template>

<script>
import ButtonComponent from './ButtonComponent.vue';

export default {
  name: 'FacebookButtonComponent',
  components: {
    ButtonComponent,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'primary',
      validator(value, props) {
        return ['primary', 'secondary', 'tertiary'].includes(value);
      },
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      googleLoginWrapperButton: null,
    };
  },
  mounted() {
    window.FB.init({
      appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      oauth: true,
      status: true,
      cookie: true,
      xfbml: true,
      version: 'v19.0',
    });
  },
  methods: {
    handleClick() {
      window.FB.login((response) => {
        if (response.authResponse) {
          this.$emit('login', {
            accessToken: response.authResponse.accessToken,
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, {
        scope: 'public_profile,email',
        auth_type: 'rerequest',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  height: 53px;
  width: 53px;
  font-size: 29px;
  padding: 0;

  &.facebook-btn {
    background-color: #3B5998;

    i {
      color: #fff;
    }
  }
}
</style>
